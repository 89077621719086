import { Button } from '@appfolio/react-gears';
import Modal, { ModalBody, ModalHeader } from '@im-frontend/components/Modal';
import { maybeReportException } from '@im-frontend/utils/errors';
import isBlank from '@im-frontend/utils/isBlank';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Flash from '~/components/shared/Flash';
import MfaStore from '~/stores/MfaStore';
import Form, { TextFormField } from '~/utils/forms2';
import { inject } from '~/utils/inject';

interface Props {
  appStore: {
    mfa: MfaStore;
  };
}
interface Values {
  token: number;
}
const MfaModal = (props: Props) => {
  const {
    cancelMfa,
    closeModal,
    modalIsOpen,
    submitToken,
    mfaPhoneNumberLast4,
    mfaMethod,
  } = props.appStore.mfa;
  const [verifyError, setVerifyError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (modalIsOpen) {
      setVerifyError(null);
    }
  }, [modalIsOpen]);
  return (
    <Modal
      backdrop
      fade
      isOpen={modalIsOpen}
      modalClassName="mfamodal"
      backdropClassName="mfamodal"
    >
      <ModalHeader>Two-Factor Authentication</ModalHeader>
      <ModalBody>
        <Form<Values>
          onSubmit={async values => {
            try {
              setIsSubmitting(true);
              setVerifyError(null);
              await submitToken(values.token);
            } catch (err) {
              setVerifyError(err);
              maybeReportException(err);
            } finally {
              setIsSubmitting(false);
            }
          }}
        >
          {() => (
            <>
              {/*This is only necessary because toasts dont work in modals in the portal*/}
              {verifyError && <Flash cause={verifyError} />}
              {mfaMethod === 'totp'
                ? 'Please enter the code from your authenticator app'
                : mfaPhoneNumberLast4 &&
                  `We sent a verification code to a phone number ending in ${mfaPhoneNumberLast4}`}
              <TextFormField
                name="token"
                label="Verification Code"
                validate={(verificationCode: any) => {
                  if (isBlank(verificationCode)) {
                    return 'Please enter the verification code';
                  }
                  if (!verificationCode.match(/^\d{6,12}$/)) {
                    return 'Please enter the code you received';
                  }
                  return null;
                }}
                autoFocus
              />
              <div className="d-flex">
                <Button
                  color="primary"
                  type="submit"
                  className="mr-3"
                  disabled={isSubmitting}
                >
                  Verify
                </Button>
                <Button
                  color="secondary"
                  onClick={async () => {
                    cancelMfa();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};
export default inject('appStore')(observer(MfaModal));
