import { Icon } from '@appfolio/react-gears';
import { NotReportedError } from '@im-frontend/utils/errors';
import { asCurrency, asDate } from '@im-frontend/utils/fmt';
import { isArray } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import CopyToClipboard from './CopyToClipboard';
import ShowMore from './ShowMore';

const uselessPrefix = /^(Error|Validation failed):? ?/i;

export const vagueMessage = "We're sorry; something went wrong.";
export const readOnlyMessage = 'Running on read only mode.';
export const vagueMessageForStatus: Record<number, string> = {
  403: 'You are not allowed to perform this action.',
};

const paymentLimitLink = (
  <Link to="/payments/ach_limits" target="_blank">
    View payments limits.
  </Link>
);
// Errors in here WILL BE DISPLAYED TO USERS
// Do not put error messages that are not useful to GPs or LPs or use the wrong terminology
// Eg
// Bad: 'cannot find fund'
// Better: 'The investment does not exist. Please refresh the page and try again.'
const errorMessageTable: {
  [index: string]:
    | React.ReactNode
    | string
    | ((fields: any) => React.ReactNode);
} = {
  ApmResyncRequired: vagueMessage,
  OfflinePaymentAlreadySynced: ({
    syncedPaymentUrl,
  }: {
    syncedPaymentUrl: string;
  }) => (
    <>
      Sorry, this distribution already has a paid bill in APM. Please{' '}
      <a href={syncedPaymentUrl} target="_blank" rel="noopener noreferrer">
        void the payment in APM
        <Icon className="pl-1" name="external-link" />
      </a>{' '}
      and then create paid bills again.
    </>
  ),
  LayeredDistributionPartialVisibility:
    'Sorry, you do not have access to all of the investments that are a part of this layered distribution.',
  DistributionScheduleMismatchError:
    'TWR could not be calculated because a distribution in selected period has a different distribution schedule from the Investment.',
  // This one is only shown to CS people
  ResetDataAfterSetupCompleteError:
    'Cannot reset all data when im_firm_infos.setup_completed_on is present',
  InvestorDeleteAssociationError:
    'The investor cannot be deleted because it has either a position in an Investment, an associated transaction, an associated contact, or a saved document',
  PositionDeleteAssociatedPositionsError:
    'Positions cannot be deleted if there are any associated transactions',
  TestError: 'This is the test error code',
  TestFieldsError: ({ customField }: { customField: string }) =>
    `This is a test error code with customizable fields! Your custom field is: "${customField}"`,
  UnknownErrorCodeError: 'This error code does not exist',
  NachaInvalidError: ({ nachaField }: { nachaField: string }) =>
    `Error downloading the NACHA file. ${nachaField} must be numbers only and cannot contain letters or special characters.`,
  InvestorPaymentsEnrollmentMissingFields: ({
    missingFields,
  }: {
    missingFields: string;
  }) => `Registering for Investor Payments ACH requires ${missingFields}`,
  SyncedBankAccountNumberCannotBeAlphanumericError:
    'Bank account number must contain only numbers and hyphens',
  PositionExitHasCapitalBalance:
    'This position cannot end because it has unreturned capital',
  PositionExitAlreadyEnded:
    'This position cannot end because it has already ended',
  PositionExitTooSoon:
    'This position cannot end before the start date of the fund, position, or a contribution of this position',
  PositionExitOnlyOpenEndedFunds: vagueMessage,
  PositionDeleteAssociatedIncompleteSignatureRecipients:
    'This position cannot be deleted because it has pending signature requests',
  PositionTransferTooSoon: ({ earliestDate }: { earliestDate: Date }) =>
    `This position must be transferred on or after ${asDate(
      earliestDate,
      'MM/DD/YY'
    )}`,
  AssetUpdateExternalIdNotUnique:
    'There is already an asset or investment linked to this property',
  ContactIsPrimary:
    'This contact cannot be deleted because it is an associated primary contact for an Investing Entity',
  SplitPdfWrongStartsOn:
    'Starting page number exceeds the amount of pages within this document',
  SplitPdfWithInvalidPdf:
    "Sorry, the PDF you've uploaded is either corrupted or password protected",
  TransferPositionAssociatedIncompleteSignatureRecipients:
    'This position cannot be transferred because it has pending signature requests',
  BadPositionTransferWithFutureTxns:
    'Sorry, this transfer cannot be completed because this position has distributions or contributions that occur after the selected effective date. Please submit a support request for any necessary assistance.',
  UnableToAddWatermarkError: ({ name }: { name: string }) =>
    `Unable to apply watermarks to ${name}; please double check that the uploaded file is a .pdf and reupload it.`,
  PdfRequiresReexportingError: ({ name }: { name: string }) =>
    `Unable to apply watermarks to ${name}; please re-export the document as a .pdf and reupload it.`,
  AddProspectDuplicate:
    'This investment already has a prospect with this investing entity and membership class',
  GpClassCommitmentsOrContributionsError: ({
    membershipClassName,
  }: {
    membershipClassName: string;
  }) =>
    `Sorry, the ${membershipClassName} membership does not support positions with any commitments or contributions`,
  MissingMfaConfigError: vagueMessage,
  MfaError: 'Something went wrong during MFA, please try again', // abstract base class; probably never sent in responses
  MfaTotpIncorrectError: 'The code was incorrect, please try again',
  MfaTwilioError: vagueMessage, // abstract base class; probably never sent in responses
  MfaTwilioRequestTokenError: vagueMessage,
  MfaTwilioVerifyPhoneNumberTooFrequently:
    'You have attempted to verify your account too many times in a short period. Please wait a few minutes before trying again.',
  MfaTwilioFraudBlocked:
    'Your account has been locked due to suspicious activity. Please try again in 12 hours or choose another phone number.',
  MfaTwilioPhoneCallBlocked:
    'We cannot send code to this phone number via Phone Call option. Please choose another phone number.',
  MfaTwilioPhoneCallNotSupported:
    'We cannot send code to this phone number via Phone Call option. Please choose another phone number.',
  MfaTwilioTokenAlreadyUsedError: 'The code was already used, please try again',
  MfaTwilioTokenIncorrectError: 'The code was incorrect, please try again',
  MfaTwilioPhoneNumberInvalidError:
    'The phone number or country code was invalid, please try again',
  MfaTwilioPhoneCannotReceiveSmsError:
    'The phone number cannot receive SMS text messages, please try the Phone Call option',
  MfaTwilioVerifyTokenLimitError:
    'Your account has been locked due to too many failed attempts, please try again in 10 minutes or choose another phone number.',
  MfaTwilioPhoneNumberLocked:
    'Your account has been locked due to too many failed attempts, please try again in 24 hours or choose another phone number.',
  MfaTwilioRequestTokenLimitError:
    'Too many requests, please try again in 10 minutes or choose another phone number.',
  RemoveAssignedRoleError:
    'You cannot delete a role with users assigned to it. Please assign users to another role and try again.',
  TaskRelatedToNotReachableError: ({ kind }: { kind: string }) =>
    `The assigned user does not have access to this ${kind}`,
  ActivatedEmailAddress: (
    <>
      Sorry, this email address is already being used.
      <Link to="/login"> Log in.</Link>
    </>
  ),
  InvalidToken: 'This link is invalid',
  FOADisableSignatures: 'The investment has pending fundraising signatures.',
  AtLeastOneAdminRequiredError: 'You must have at least one system admin.',
  InternalUserCanNotCreateUserAfterPaymentIsReadyError:
    'Investor Payments is ready; however, you are not allowed to create a user.',
  InternalUserCanNotUpdateIPRoleError:
    'You are not allowed to give a user a role with the Send Payments permission.',
  InternalUserCanNotEnablePaymentsError:
    'You are not allowed to set the onboarding stage to Payments Ready.',
  InternalUserUpdateIPPermissionForRoleError:
    'You are not allowed to change a role to have Investment Payments permission.',
  InternalUserCanNotUpdateMidError:
    'You are not allowed to config Merchant Id.',
  InternalUserCanNotUpdateLidError:
    'You are not allowed to config Location Id.',
  ProspectContactMissingEmailAddress:
    "Not all prospects' contact have email address.",
  PositionUnrollHasTransactions:
    'You cannot unroll a transfer where the destination position has transactions.',
  PositionUnrollChildTransferred:
    'You cannot perform this operation when the destination position is transferred or ended',
  PositionUnrollFailed:
    'The position you selected is not transferred, please contact kiwi',
  PositionCapitalBalanceBadDate:
    "Provided date needs to be after position's start date and cannot be in the future",
  DistributionEffDateAfterPositionStartDate:
    "Distribution's effective date must be after the position's start date",
  CannotCreateDistItemsPositionIsEnded:
    'Cannot create distribution items for positions that are ended/transferred',
  NotSameFund: 'Position and Distribution must be in the same fund',
  DuplicateItems:
    'Distribution Item for this position/distribution/type already exists',
  StopIfPaymentsOrBills:
    'This cannot be done becuase the desired distribution is involved in payments or bills',
  PotentialNegativeCapBal:
    'Position would have a negative capital balance, please choose a different type/amount',
  NotAllowedToSignPaymentAgreementAddendumError:
    'A System Admin on your account must accept this agreement. Please ask them to accept to continue.',
  PositionHasAssociatedPaymentsError:
    'This position cannot be reassigned because there is one or more associated payments',
  PositionIsUnableToMerge: ({ reason }: { reason: string }) =>
    `Positions can't be merged due to: ${reason}`,

  IPDistributionVelocityAmountLimit1Error: (
    <>
      This payment would exceed your maximum allowed payments for today.{' '}
      {paymentLimitLink}
    </>
  ),
  IPDistributionVelocityCountLimit1Error: (
    <>
      This payment would exceed your maximum allowed number of transactions for
      today. {paymentLimitLink}
    </>
  ),
  IPDistributionVelocityAmountLimit60Error: (
    <>
      This payment would exceed your maximum allowed payments for the quarter.{' '}
      {paymentLimitLink}
    </>
  ),
  IPDistributionVelocityAmountLimitQuarterlyError: (
    <>
      This payment would exceed your maximum allowed payments for this quarter.{' '}
      {paymentLimitLink}
    </>
  ),
  IPDistributionVelocityCountLimitQuarterlyError: (
    <>
      This payment would exceed your maximum allowed number of transactions for
      this quarter. {paymentLimitLink}
    </>
  ),
  IPVelocityCountLimit180CapitalError: (
    <>
      This payment would exceed your maximum allowed number of capital event
      transactions for the last 180 days. {paymentLimitLink}
    </>
  ),
  IPVelocityAmountLimit180CapitalError: (
    <>
      This payment would exceed your maximum allowed capital event payments for
      the last 180 days. {paymentLimitLink}
    </>
  ),
  IPContributionVelocityCountLimit1Error: ({
    countLimit,
  }: {
    countLimit: string;
  }) => (
    <>
      This payment would exceed your maximum allowed number of transactions (
      {countLimit}) for this entity today. Please try again tomorrow.
    </>
  ),
  IPContributionVelocityAmountLimit1Error: ({
    amountLimit,
  }: {
    amountLimit: string;
  }) => (
    <>
      This payment would exceed your maximum allowed transaction amount (
      {asCurrency(amountLimit, { withCents: true, withSymbol: true })}) for this
      entity today. Please try again tomorrow.
    </>
  ),
  IPContributionVelocitySingleTransactionAmountLimitError: ({
    amountLimit,
  }: {
    amountLimit: string;
  }) => (
    <>
      This payment would exceed the maximum allowed transaction amount (
      {asCurrency(amountLimit, { withCents: true, withSymbol: true })}). Please
      try again with a smaller amount.
    </>
  ),
  IpContributionSubmissionError:
    'Something went wrong, please try again in a few hours.',
  IPBatchIncorrectStatusError: vagueMessage,
  IPBatchAchNotAllowedWhenChecksendOnlyError:
    'Some investing entities are set to be paid via Investor Payments - ACH, but you are not enabled for this payment method. Please change their payment method(s) to proceed with making this payment.',
  IPContributionStaleError:
    'Please refresh the page and review the updated contribution amounts before sending payments.',
  IPDistributionAlreadyPaidError:
    'This distribution cannot be edited because it has already been paid',
  IPCheckSendRequiredFieldsError: ({ fields }) =>
    `Registering for Investor Payments CheckSend requires ${fields}`,
  IPBatchMustPayOnePersonError: 'Must pay at least one person',
  IPStaleDistributionItemsError:
    'This distribution has been edited. Please refresh the page and review the updated distribution amounts before sending payments.',
  CannotDeleteContributionWithPayment:
    'You can not delete this contribution because there is a payment record associated with it. Please contact support if you need to delete this contribution.',
  SignerHasLinkedDistributionBankAccountError:
    'This contact owns the bank account associated with this investing entity, please update the investing entity bank account.',
  ContactHasLinkedDistributionBankAccountError: ({
    entities,
  }: {
    entities: {
      name: string;
      id: string;
    }[];
  }) => (
    <>
      This contact owns the bank accounts associated with following entities:
      <div>
        {entities &&
          entities.map(({ name, id }) => (
            <li key={id}>
              <Link
                to={`/investors/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {name}
                <Icon className="pl-1" name="external-link" />
              </Link>
            </li>
          ))}
      </div>
      Please update the investing entity bank account.
    </>
  ),
  IPActivePaymentRecordsError: 'This distribution has already been paid.',
  IPPaymentProcessingError:
    'Payments are still processing. Please try again on the next business day',
  NylasEmailAlreadyIntegrated: ({
    integratedUserName,
  }: {
    integratedUserName: string;
  }) =>
    `${integratedUserName} has already integrated the email you are attempting to connect.  Please have them disconnect first, or ask them to enable the following settings for that email: "Allow all users to send from this email", "Synced emails visible to all users".`,
  NylasAuthWithDifferentEmail: ({
    integratedEmail,
  }: {
    integratedEmail: string;
  }) =>
    `You have already integrated ${integratedEmail}, please disconnect this integration first before integrating another email.`,
  NylasAuthWithDifferentProvider: ({
    integratedEmail,
    integratedProvider,
    differentProvider,
  }: {
    integratedEmail: string;
    integratedProvider: string;
    differentProvider: string;
  }) =>
    `You have integrated ${integratedEmail} with ${integratedProvider} already.  If you would like to integrate with ${differentProvider} instead, please disconnect the email and connect again with the proper provider.`,
  BadOAuthState: vagueMessage + ' Please try again.',
  NylasReauthWithDifferentEmail:
    'Email integration could not be completed, please reconnect your account with the previously connected email address or you can disconnect your account and connect again with a new email address.',
  NylasEmailAlreadyConnected: ({ email }: { email: string }) =>
    `The email address ${email} is already connected to another user.`,
  EmailIsNotInContactEmailList: ({ contactName }: { contactName: string }) =>
    `Sorry, there is no matching email address for ${contactName}. Please add the email address to the Contact Information and try again.`,
  IPIneligibleToBeLinkedError: vagueMessage,
  PlaidStaleSameDayMicroDepositsError: vagueMessage,
  PlaidTimeoutError: vagueMessage,
  ApmSyncPropertyDoesNotExist: 'A property at this url could not be found.',
  ApmSyncNegativeDistributionItem: 'Negative amounts cannot be synced.',
  ApmSyncDistributionItemForPastPosition:
    'This distribution contains past positions and cannot be synced.',
  DuplicateTag: 'Tag name already exists.',
  CaptchaScoreIsLow: vagueMessage,
  InvalidRefreshToken: vagueMessage,
  MissingVelocityLimitError:
    'There is an issue pulling Velocity Limits from Salesforce. Ensure they are all set.',
  MissingCapitalEventVelocityLimitError:
    'There is an issue pulling capital event Velocity Limits from Salesforce. Ensure they are all set.',
  PlaidAccountTransferBlockedError:
    'Plaid is unable to send micro-deposits to this account. This may happen because the account number is invalid or the account is frozen. Please try adding another bank account.',
  PlaidAccountCannotBeLinkedError:
    'Sorry, that account could not be linked. Please try to link with account numbers or try a different account.',
  PlaidInstitutionNotRespondingError:
    'Plaid is having issues with instant link for this bank. Please retry with the manual link method.',
  PlaidMaintenanceError:
    'Plaid is temporarily unavailable due to maintenance. Visit https://status.plaid.com/ for status and please try again in a few hours.',
  PlaidInternalServerError:
    'Plaid is not responding. Please try again in a few hours. If the issue persists contact us.',
  QuestionnaireMissingContact:
    "Some prospects don't have emails, please add emails for all prospects.",
  SignersMissingEmail:
    "Some signers don't have emails, please add emails for all signers.",
  InternalUserNoSignatureRecipientCreation:
    'Internal users are not allowed to send signature requests.',
  InternalUserNoSignaturePacketCreation:
    'Internal users are not allowed to create signature requests.',
  InternalUserNoTurnOnAutoRequestSignature:
    'Internal users are not allowed to turn on auto signature requests.',
  PdfIsPasswordProtectedError:
    'Sorry, the document is password protected. Please upload an unencrypted document and try again.',
  CannotCreateFromPasswordProtectedPdf:
    'Sorry, the document is password protected. Please upload an unencrypted document and try again.',
  CorruptedDocumentError: ({ documentName }: { documentName: string }) =>
    `Sorry, we cannot process this document ${documentName} for merge fields. Please check if this file is corrupted.`,
  CannotCreateFromCorruptPdf:
    'Sorry, we cannot process this document. Please check if this file is corrupted.',
  HiddenDuplicatePortalLogin:
    'The email address is already being used as a portal login by another contact and cannot be duplicated. Please contact your General Partner to add this associated contact.',
  ContactDeleteSignatureAssociationError:
    "This contact can't be deleted because it is associated with an investing entity that has pending signature requests.",
  ContactDeleteIPContributionAssociationError:
    "This contact can't be deleted because it is associated with contribution payments.",
  FfcFieldsNotAllowedGp: 'FFC fields are not allowed with that payment type.',
  FfcFieldsNotAllowed:
    'You cannot fill out this information at this time, contact your investment manager to save this information.',
  InvalidEmbeddedContacts:
    'Some of the contacts for this investing entity do not exist in your database',
  CapitalCallItemLinked:
    'One of the items for this capital call is linked to a contribution',
  UnarchiveNotFundraising:
    'The fund you are trying to unacrhive is not a hidden fundraising investment',
  ReactivateNotClosed:
    'The Fund you are trying to reactivate is not a closed investment',
  GPClassProspectCannotHaveContribution:
    "A prospect that is a GP Class member can't have contributions.",
  CategoryHasRelatedChecklistItems:
    'You cannot delete a category that has checklist items',
  IPUnsupportedApproverMfaError:
    'Sorry, we do not support international MFA phone numbers for payment approvals yet. Please contact support to reset your MFA to a different method.',
  NoProvidedRecipientsMailableError:
    'There are no email addresses for for these investing entities.',
  NoFirmEmail:
    'Our system requires a firm email for sending email. Please add a firm email and retry.',
  NoFirmInfo:
    'This account does not seem properly configured yet. Please contact support to complete the setup.',
  ProspectHasPendingRecipientsError: ({
    fundId,
  }: {
    fundId: number | string;
  }) => (
    <>
      A prospect cannot be removed when a signature request is pending. Please
      cancel the{' '}
      <Link to={`/investments/${fundId}/signatures?mode=fundraising`}>
        signature request
      </Link>{' '}
      and try again.
    </>
  ),
  InvalidGpClassTransactionError: (
    <span>
      Sorry, in order to enable our new General Partner class all of the
      investors in the existing class can not have any commitments,
      contributions or capital calls. Use our{' '}
      <Link to="/reporting/positions">View All Positions</Link> report to find
      the General Partner position that has a transaction associated with it.
    </span>
  ),
  InvalidGpClassFundraisingMemberError:
    "Sorry, in order to enable our new General Partner class the existing class can't be used in a fundraising investment.",
  DuplicateSharePriceError:
    'You cannot have conflicting prices for a membership class on the same date.',
  ViewOnlyUserCantMakeChangesError:
    'Due to having View Only access, you are not able to save any changes.',
  CannotCreateClassesWithoutFundsError:
    'In order to add membership classes there needs to be at least one investment in the system.',
  FoaMixedRecipientsError: 'Please email positions and prospects separately',
  ScheduledEmailShareAttachmentsError:
    'Emails with attachments shared to the Investor Portal cannot be sent later as a scheduled email.',
  ConflictingKpiMapError: ({
    assetName = '',
  }: {
    assetName: string | string[];
  }) => {
    const names = isArray(assetName) ? assetName : [assetName];
    return `A mapping already exists for ${names.join(
      ', '
    )}. Update the report type and/or date range for either ${
      names.length > 1 ? 'these' : 'this'
    } or the other mapping.`;
  },
  AssociatedAlphaReportDeleteError:
    'This asset cannot be deleted because it has Alpha reports associated with it.',
  ReinvestedAsContribution:
    'Sorry, you cannot delete distributions containing reinvestment contributions. Please delete all associated contributions first.',
  FundDeleteNotPossibleBecauseAttachedIpPayors: ({ name }: { name: string }) =>
    `You cannot delete ${name} because there are attached payment bank accounts.`,
  TooManyContactCustomAttributeDefinitions:
    'You have reached the maximum number of custom contact attributes.',
  ProspectHasPaymentRecordsError:
    'Prospects could not be deleted because one or more selected prospects have payment records associated with them.',
  ResetIPOnboardingNotStarted: 'Onboarding has not been started.',
  ResetIPOnboardingAchTxnsPresent:
    'No ACH transactions may exist in the last 5 days',
  // Should never be shown to end users (our UI should tweak values onSubmit to prevent it!)
  // Okay(ish) to be vague in this case.
  IncompatibleFundSetting:
    'Your update is not compatible with the investment settings.',
  CustomMappingAlreadyDefined: 'A custom chart already exists with this name.',
  LimitedContactAccessUserDeletingEmailDraft:
    'You cannot send this email because it has recipients that you do not have access to.',
  RecipientsNotInContextError: ({ names }: { names: string[] }) =>
    `${names?.join(
      ', '
    )} are no longer eligible recipients and must be removed to proceed.`,
  CreateFromPdfFailed:
    'New Offering creation failed, please try again or click skip to create manually.',
  CannotUpdateActivatedProspectError:
    'A prospect that has been activated cannot be updated.',
  InvalidEffectiveDateError:
    'Effective date must be after the period start date.',
};

export function errorStringLookup(data: {
  errorCode: string;
  fields: any;
}): string | React.ReactNode {
  const msg_or_func = errorMessageTable[data.errorCode];
  if (!msg_or_func) {
    return null;
  }
  if (typeof msg_or_func === 'string' || React.isValidElement(msg_or_func)) {
    return msg_or_func as typeof msg_or_func;
  }
  if (typeof msg_or_func === 'function') {
    return msg_or_func(data.fields);
  }
}

/**
 * Extract the message from an object/string, or return a
 * default vague message.
 */
function toMessage(e: any) {
  return typeof e === 'string' ? e : (e && e.message) || vagueMessage;
}

/**
 * Given an Error, describe it as human-readable HTML that is
 * suitable for rendering with React. Structured API errors
 * are rendered as a plaintext message (for responses with
 * .type and .message) or an HTML list (for responses with
 * an .errors subcollection).
 *
 * IMPORTANT: when considering the API response's `.errors` collection,
 * any error that has a `field` key is ignored for purposes of display;
 * these are assumed to be handled by form logic. If all individual
 * errors are ignored, then the error's top-level `message` key is
 * used if present, else this function returns a vague message.
 */
export default function formatError(err: any, technicalDetails = false) {
  if (!err) {
    return vagueMessage;
  } else if (typeof err === 'string') {
    return err;
  } else if (err instanceof NotReportedError && err.shouldToastMsg) {
    return err.message;
  } else if (err.response) {
    const { data, status } = err.response as { data: any; status: number };
    if (data) {
      const { id, type, message, name } = data;
      let summary;
      if (
        name === 'ActiveRecord::StatementInvalid' &&
        message ===
          'Mysql2::Error: Cannot execute statement in a READ ONLY transaction'
      ) {
        summary = readOnlyMessage;
      } else {
        summary =
          type && message
            ? String(message).replace(uselessPrefix, '')
            : vagueMessageForStatus[status] || vagueMessage;
      }

      let triageInfo = null;
      if (id && technicalDetails) {
        const href = `https://sentry.io/organizations/appfolio/issues/?query=${id}`;
        triageInfo = (
          <>
            <strong>Server error:</strong>
            &nbsp;
            {message}
            <CopyToClipboard
              className="mt-2"
              label="Event ID"
              value={id}
              href={href}
            />
          </>
        );
      }

      if (data.errorCode) {
        return (
          <ShowMore summary={errorStringLookup(data) || vagueMessage}>
            {triageInfo}
          </ShowMore>
        );
      }

      const errors = (data.errors || [])
        .filter((e: any) => !e.field)
        .filter((e: any) => e.userVisible);

      switch (errors.length) {
        case 0:
          return <ShowMore summary={summary}>{triageInfo}</ShowMore>;
        case 1:
          return (
            <ShowMore summary={toMessage(errors[0]) || summary}>
              {triageInfo}
            </ShowMore>
          );
        default:
          return (
            <ShowMore summary={message || vagueMessage}>
              {triageInfo}
              <ol>
                {errors
                  .map((e: any) => toMessage(e) || vagueMessage)
                  .map((m: any) => (
                    <li key={m}>{m}</li>
                  ))}
              </ol>
            </ShowMore>
          );
      }
    }
    if (technicalDetails) {
      return (
        <ShowMore summary={vagueMessage}>
          <strong>Server error:</strong>
          &nbsp;
          {err.response.status}
          &nbsp;
          {err.response.statusText}
        </ShowMore>
      );
    }
    return <ShowMore summary={vagueMessage} />;
  }
  if (technicalDetails) {
    return (
      <ShowMore summary={vagueMessage}>
        <strong>Client error:</strong>
        &nbsp;
        {toMessage(err)}
      </ShowMore>
    );
  }
  return <ShowMore summary={vagueMessage} />;
}
